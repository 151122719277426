<template>
  <el-dialog
    :visible.sync="visible"
    :title="!dataForm.id ? $t('add') : $t('update')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmitHandle()"
      label-width="160px"
    >
      <el-form-item label="权益代码">
        <el-input :value="dataForm.rightCode" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="权益名称">
        <el-input v-model="dataForm.rightName"></el-input>
      </el-form-item>

      <el-form-item label="供应商">
        <el-select v-model="dataForm.supplierAppCode" placeholder="请选择">
          <el-option
            v-for="item in SysappsupplierList"
            :key="item.appCode"
            :label="item.appDesc"
            :value="item.appCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-switch
          v-model="dataForm.status"
          :active-value="1"
          :inactive-value="0"
          active-text="正常"
          inactive-text="停用"
        ></el-switch>
      </el-form-item>
      <el-form-item label="实名认证">
        <el-switch
          v-model="dataForm.realNameFlag"
          active-value="1"
          inactive-value="0"
          active-text="需要"
          inactive-text="不需要"
        ></el-switch>
      </el-form-item>

      <el-form-item label="权益简介">
        <el-input type="textarea" v-model="dataForm.rightBriefDesc"></el-input>
      </el-form-item>
      <el-form-item label="权益描述">
        <el-input v-model="dataForm.rightDesc" type="textarea"></el-input>
      </el-form-item>

      <el-form-item label="使用说明">
        <el-input v-model="dataForm.useDesc" type="textarea"></el-input>
      </el-form-item>

      <el-form-item label="服务时间描述">
        <el-input v-model="dataForm.serviceTimeDesc"></el-input>
      </el-form-item>

      <el-form-item label="使用有效期描述">
        <el-input v-model="dataForm.validDateDesc"></el-input>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmitHandle()"
        :loading="loading"
        loading-type="spinner"
        >{{ $t('confirm') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  data() {
    return {
      loading: false,
      visible: false,
      dataForm: {},
      SysappsupplierList: [],
    };
  },
  computed: {
    dataRule() {
      return {
        packageId: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        count: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        batchDesc: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  created() {
    // this.getCodeList();
    this.getSysappsupplierList();
  },
  methods: {
    // 获取供应商列表

    getSysappsupplierList() {
      return this.$http
        .get('/sysappsupplier/list')
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          console.log(res.data, '获取供应商列表');
          this.SysappsupplierList = res.data;
        })
        .catch(() => {});
    },
    async init() {
      this.visible = true;
      const res = await this.$http.get(
        'health/right/info?id=' + this.dataForm.id
      );
      this.dataForm = res.data.data;
      console.log(res);
      // /
    },
    // 表单提交
    dataFormSubmitHandle: debounce(
      function () {
        this.$refs['dataForm'].validate(async (valid) => {
          if (!valid) {
            return false;
          }
          this.loading = true;

          const {
            id,
            realNameFlag,
            rightBriefDesc,
            rightName,
            status,
            rightDesc,
            useDesc,
            serviceTimeDesc,
            validDateDesc,
            supplierAppCode,
          } = this.dataForm;

          await this.$http.post('/health/right/update', {
            id,
            realNameFlag,
            rightBriefDesc,
            rightName,
            status,
            rightDesc,
            useDesc,
            serviceTimeDesc,
            validDateDesc,
            supplierAppCode,
          });

          this.visible = false;
          this.dataForm = {};
          this.$emit('refreshDataList');
          //
          this.loading = false;
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
  },
};
</script>
